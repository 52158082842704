import React from "react";
import "./menu.css";
import Lines from "../../assets/images/Hamburger.png";
import Close from "../../assets/images/Close.png";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {
  setLanguage,  
} from 'react-switch-lang';
import AnchorLink from 'react-anchor-link-smooth-scroll';

class Menu extends React.Component{    

  iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

  constructor() {
    super();
    this.state = {        
        close : true,
        bottom: false,
        language : 'en'
    };   
    this.handleSetLanguage = this.handleSetLanguage.bind(this); 
  }

  handleSetLanguage(key) {
    setLanguage(key);
    this.setState({ language : key })    
  };
    
  componentDidMount() {      
    let language = window.navigator.userLanguage || window.navigator.language;      
    if(language === 'en' || language === 'ar') {             
      this.handleSetLanguage(language);
    }
  }

  toggleDrawer = (side, open) => event => {    
    this.setState({  [side]: open, close : !this.state.close  });
  };

  slectedItemStyle = {
    backgroundColor: 'red'
  }

  clickMenu = (id) => {
    if(id === 'lan'){                 
      this.handleSetLanguage(this.state.language === 'en' ? 'ar' : 'en');             
    }    
  }  

  fullList = (side,menuText) => (
    <div      
      id="presentationMenu"
      role="presentation"
      onClick={this.toggleDrawer(side, false)}
      onKeyDown={this.toggleDrawer(side, false)}      
    >                   
      <List>
        {[
          {id: 'Asset', title: menuText.asset}, 
          {id: 'Employee', title: menuText.employee},          
          {id: 'Retaining', title: menuText.retaining}, 
          {id: 'Quality', title: menuText.quality}, 
          {id: 'Improving', title: menuText.improving}, 
          {id: 'Emerging', title: menuText.emerging}, 
          {id: 'whitePapers', title: menuText.industry},
          {id: 'Contact', title: menuText.contact},     
          {id: 'lan', title: this.state.language === 'en' ? menuText.lanAr :menuText.lanEn },
        ].map((item, index) => {
          if(item.id === 'lan') {
            return(
              <ListItem key={index}>                        
                <ListItemText primary={item.title} onClick={ ()=> { this.clickMenu(item.id)} } />            
              </ListItem>
            )
          }else {
            return(
              <AnchorLink  key={index} href={`#${item.id}`}>
                <ListItem>                        
                  <ListItemText primary={item.title} />            
                </ListItem>          
              </AnchorLink>
            )
          }
        })}
      </List>
    </div>
  );  
  
  render() {
    return (
        <div>
          <button type="button" name="menu" aria-label="Menu" className="circleBase type3" onClick={this.toggleDrawer('bottom', this.state.close ? true : false )}>      
              <img className="btnImage" src={ this.state.close ? Lines : Close } alt="" />
          </button>
          <SwipeableDrawer disableBackdropTransition={!this.iOS} disableDiscovery={this.iOS} anchor="bottom" open={this.state.bottom} onClose={this.toggleDrawer('bottom', false)}>
              {this.fullList('bottom',this.props.menuText)}
          </SwipeableDrawer>
        </div>
    );
  }
}



export default Menu;
