import React from 'react';
import template from './App.template';

class App extends React.Component {
	constructor() {
    super();
    this.state = {        
      installButton: false,
    };
	}
	
	installPrompt = null;
	componentDidMount(){
    this.showPromptChrome();
  }
  
  showPromptChrome = () => {    
    window.addEventListener('beforeinstallprompt',e=>{
      // For older browsers
      e.preventDefault();      
      this.installPrompt = e;
      // See if the app is already installed, in that case, do nothing
      if((window.matchMedia && window.matchMedia('(display-mode: standalone)').matches) || window.navigator.standalone === true){
        return false;
      }
      // Set the state variable to make button visible
      this.setState({
        installButton:true
			});
    })
  }
	
	installApp= async () => {
    if(!this.installPrompt) return false;
    this.installPrompt.prompt();
    let outcome = await this.installPrompt.userChoice;
    if(outcome.outcome==='accepted'){
      console.log("App Installed")
    }
    else{
      console.log("App not installed");
    }
    // Remove the event reference
    this.installPrompt=null;
    // Hide the button
    this.setState({
      installButton:false
    })
	}

	handleClose = () => {
		this.setState({
      installButton:false,
      promptIos: false
    })
	}
	
  render() {
		return template.call(this);
	}
}

export default App;
