import React, {Component} from 'react';
import { Container ,Row, Col } from 'react-grid-system';
import "./Strategy.css";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { getLanguage } from 'react-switch-lang';

class Strategy extends Component {
    constructor() {
        super();
          this.state = {
            currentIndex : 0 
        };        
        this.onSlideChanged = this.onSlideChanged.bind(this);
      }
    
    screenValues = [
        {                        
            backgroundColor : '#F46C00'
        },
        {                        
            backgroundColor : '#667175'
        },
        {                        
            backgroundColor : '#532E61'
        }
    ]; 

    onSlideChanged(e) {                        
        this.setState({ currentIndex: e.item });     
    }

    lastLi(item){
      if(item){
        return <li>{ item }</li>;
      }
    }

    render() {                 
      return(
        <div style={{ backgroundColor: this.screenValues[this.state.currentIndex].backgroundColor }} className="strategyWrapper" id="strategyComponent">
          <AliceCarousel mouseDragEnabled buttonsDisabled style={{ paddingTop:"60px", paddingBottom:"60px" }} onSlideChanged={this.onSlideChanged} startIndex={this.state.currentIndex} responsive={{0:{items: 1}}}>
              {this.props.texts.map((item, key) => {
                return(
                  <Container style={{ paddingBottom : "48px" }} key={key}>
                    <Row>
                      <Col sm={12}>
                          <h2 className={`strategyTitle ${getLanguage()}`}>{ item.title }</h2>
                      </Col>                  
                    </Row>
                    <Row>
                      <Col sm={12}>
                        {item.text ? 
                        <p className={`strategyTitle-p ${getLanguage()}`}>{ item.text }</p>:
                        <p className={`strategyTitle-p ${getLanguage()}`}>{ item.text1 } <span>{ item.textspan }</span> { item.text2 }</p>
                        }
                      </Col> 
                    </Row>
                    <Row>
                      <Col sm={12}>
                        {item.teamText ? 
                          <p className={`strategyTitle-p ${getLanguage()}`}>{ item.teamText }</p>:
                          <p className={`strategyTitle-p ${getLanguage()}`}>{ item.teamText1 } <span>{ item.teamTextspan }</span> { item.teamText2 }</p>
                        }
                        <ul className={`strategyTitle-ul ${getLanguage()}`}>
                            {item.l1 ? 
                              <li>{ item.l1 }</li>:<li>{ item.l1_1 }<span>{ item.l1_2 }</span>{ item.l1_3 }</li>
                            }
                            <li>{ item.l2 }</li>
                            <li>{ item.l3 }</li>
                            { this.lastLi(item.l4) }
                        </ul>
                      </Col>                  
                    </Row>              
                  </Container>
                )
              })}
          </AliceCarousel>
        </div>
      );
    }  
}

export default Strategy;