import React from "react";
import { Router, Route, Switch } from 'react-router-dom';
import {createBrowserHistory} from 'history';
import Home from '../Home/Home';

let history = createBrowserHistory();

export default function template() {
  return (
    <Router history={history}>
      <Switch>
        <Route path='/' component={(props) => <Home {...props} installButton={this.state.installButton} installApp={this.installApp} handleClose={this.handleClose}/>} />
      </Switch>
    </Router>
  );
}