import React from "react";
import "./Carousel.css";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const responsive = {
  0: {items: 1},
  760: {items: 2},
  1023: {items: 3},
};

const Carousel = (props) => {  
  return (
    <AliceCarousel mouseDragEnabled buttonsDisabled infinite={props.infinite} dotsDisabled={props.dotsDisabled} responsive={responsive} stagePadding={{paddingLeft: props.paddingLeft, paddingRight: props.paddingRight,}} id="carouselGeneral">    
      {props.children}
    </AliceCarousel>
  );
};

Carousel.defaultProps = {
  infinite: true,
  dotsDisabled: true,
  paddingLeft: 0,
  paddingRight: 0,
}

export default Carousel;
