import React from "react";
import "./footer.css";
import Logo from "../../assets/images/shockoe.png";
import ShareImg from "../../assets/images/SocialSharing.png";
import Menu from '../../components/menu/menu';
import AnchorLink from 'react-anchor-link-smooth-scroll'


class Footer extends React.Component{

  constructor() {
    super();      
    this.handleShare = this.handleShare.bind(this);
  }

  handleShare(e) {
    e.preventDefault();    
    if (navigator.share) {
      navigator.share({
        title: 'SHOCKOE | Mobile Enterprise Solutions | Android | Apple | VR | VOICE',
        text: '',
        url: 'https://shockoe.com/'
      }).then(() => {
        console.log('sharing');
      })
      .catch(err => {
        console.log(`Couldn't share `, err.message);
      });
    } else {
      console.log('web share not supported');
    }
  }

  render() {    
    return (
        <div className="footerContainer">
          <div className="barLeft">
              <AnchorLink href="#Home" className={`footerLogoContainer ${this.props.showLogo ? 'hidden':'visible'}`}>
                <img className="footerLogoImg" src={Logo} alt="" />
              </AnchorLink>
              <a href="#share" className={`footerLogoContainer ${this.props.showLogo ? 'hidden':'visible'}`} onClick={this.handleShare}>
                <img className="footerLogoImg" src={ShareImg} alt="" />
              </a>
          </div>
          <div className="barRight">
            <Menu menuText={this.props.menuText} language={ this.props.language }/>
          </div>
        </div>
    );
  }
}

export default Footer;
