import React from "react";
import "./MenuDesktop.css";
import Lines from "../../assets/images/Hamburger-Desktop.png";
import Close from "../../assets/images/Close-Desktop.png";
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import dotGridImage from '../../assets/images/DotGridMenu.png';
import {
  setLanguage,  
} from 'react-switch-lang';
import AnchorLink from 'react-anchor-link-smooth-scroll'

class MenuDesktop extends React.Component{    

  constructor() {
    super();
    this.state = {        
        close : true,
        right: false,
        language : 'en'
    };   
    this.handleSetLanguage = this.handleSetLanguage.bind(this); 
  }

  handleSetLanguage(key) {
    setLanguage(key);
    this.setState({ language : key })    
  };
    
    componentDidMount() {      
      let language = window.navigator.userLanguage || window.navigator.language;      
      if(language === 'en' || language === 'ar') {             
        this.handleSetLanguage(language);
      }
    }

   toggleDrawer = (side, open) => event => {    
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }    
        this.setState({  [side]: open, close : !this.state.close  });
    };

    slectedItemStyle = {
      backgroundColor: 'red'
     }

  clickMenu = (id) => {
    if(id === 'lan'){                 
      this.handleSetLanguage(this.state.language === 'en' ? 'ar' : 'en');             
    }    
  }  

  fullList = (side,menuText) => (
    <div      
      id="presentationMenuDesktop"
      role="presentation"
      onClick={this.toggleDrawer(side, false)}
      onKeyDown={this.toggleDrawer(side, false)}      
    >
      <div style={{display: 'flex'}} >
        <div style={{width:"89%", paddingLeft: '16px'}}>
          <h3>{menuText.title}</h3>
        </div>
        <div style={{width:"11%"}} >
        <button type="button" name="Close" aria-label="Close-Menu" className="menuButtonClose" onClick={this.toggleDrawer('right', this.state.close ? true : false )}>      
          <img className="btnImage" src={ Close } alt="Shockoe Mobile - Clore" />
        </button>
        </div>                
      </div>      
      <div style={{ textAlign: 'right' }}>
        <img width="92%" className="" alt="" height="40" src={dotGridImage}/>
      </div>
      
      <List>
        {[
          {id: 'Asset', title: menuText.asset}, 
          {id: 'Employee', title: menuText.employee},            
          {id: 'Retaining', title: menuText.retaining}, 
          {id: 'Quality', title: menuText.quality},
          {id: 'Improving', title: menuText.improving}, 
          {id: 'Emerging', title: menuText.emerging}, 
          {id: 'whitePapers', title: menuText.industry},
          {id: 'Contact', title: menuText.contact},     
          {id: 'lan', title: this.state.language === 'en' ? menuText.lanAr :menuText.lanEn },
        ].map((item, index) => {
          if(item.id === 'lan') {
            return(
              <ListItem key={index} style={{cursor: 'pointer'}}>                        
                <ListItemText primary={item.title} onClick={ ()=> { this.clickMenu(item.id)} } />            
              </ListItem>
            )
          }else {
            return(
              <AnchorLink  key={index}  href={`#${item.id}`}>
                <ListItem>                        
                  <ListItemText primary={item.title} />            
                </ListItem>          
              </AnchorLink>
            )
          }
        })}
      </List>      
    </div>
  );  
  
  render() {        
    return (
        <div>
          <button type="button" name="menu" aria-label="Menu" className="menuButtonHamburguer" onClick={this.toggleDrawer('right', this.state.close ? true : false )}>      
            <img className="btnImage" src={ Lines } alt="Shockoe Mobile - Hamburguer" />
          </button>
          <Drawer anchor="right" open={this.state.right} onClose={this.toggleDrawer('right', false)}>
              {this.fullList('right',this.props.menuText)}
          </Drawer>
        </div>
    );
  }
}



export default MenuDesktop;
