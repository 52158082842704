import React, {Component} from 'react';
import { Container ,Row, Col } from 'react-grid-system';
import "./productSuccess.css";
import { getLanguage } from 'react-switch-lang';
import PercentageChart from '../PercentageChart/PercentageChart';
import clickImage from '../../assets/images/clickHere.png';

class ProductSuccess extends Component {    

    constructor() {
        super();
          this.state = {
            currentElement : 0,  
        };        
        this.onClick = this.onClick.bind(this);
      }

    screenValues = [
        {                                    
            chartValue:0
        },
        {                                    
            chartValue:30
        },
        {                                    
            chartValue:70
        },
        {                                    
            chartValue:100
        }
    ];  
    
    
    onClick() {      
        const { currentElement } = this.state;                  
        this.setState({ currentElement: currentElement === 3 ? 0 : currentElement + 1 });     
    }


    renderImage(tapText){
        
        if(this.state.currentElement === 0){
            return (
                <div className="divTap">
                    <p className="tapHere">{ tapText }</p>
                    <img className="logo" alt="Shockoe Mobile" height="40" width="28" src={clickImage}/>
                </div>
            );
        }

        if(this.state.currentElement === 1){
            return (
                <div className="divTap">
                    <img className="logo" alt="Shockoe Mobile" height="40" width="28" src={clickImage}/>
                </div>            
            )
        }
        
    }

    render() {        
        const { currentElement } = this.state;
        return(
          <div className="strategyWrapper" id="productSuccess">
            <Container>
              <Row>
                  <Col sm={12}>
                    <h2 className="productSuccessTitle">{this.props.title}</h2>
                  </Col>                  
              </Row>

              <Row>                
                <Col sm={12} align="center">
                    <span className="dot"></span>
                    <div style={{ top : this.state.currentElement === 0 ? '55%' : '50%' }} className="productTextChart" onClick={this.onClick} >
                        { this.props.texts[currentElement].chartText.map((textt, index) =>
                            <p key={index} >{textt}</p>
                        )}                           
                        {this.renderImage(this.props.tap)}
                    </div>                    
                    <PercentageChart backgroundColor="#FBFBFB" barColor="#F46C00" value={this.screenValues[currentElement].chartValue} onClick={this.onClick} />                                    
                </Col>
              </Row>
              <Row>
                <Col sm={12} align="center">
                  <div className="productTextContainer">
                    <p className={`productText ${getLanguage()}`}>{ this.props.texts[currentElement].text } <strong>{ this.props.texts[currentElement].span }</strong>  { this.props.texts[currentElement].secondText }</p>
                  </div>
                </Col> 
              </Row>             
            </Container>
          </div>
        );
      }  
}

export default ProductSuccess;