import React, {Component} from 'react';
import './CardWP.css';
import ReactCardFlip from 'react-card-flip';
import { Container, Row, Col } from 'react-grid-system';
import { getLanguage } from 'react-switch-lang';
import DoGrid from '../../assets/images/DotGrid.png';
import DashboardMeasure from '../../assets/images/Measure_measure.png';
import DashboardEnsure from '../../assets/images/Ensure_success.png';
import DashboardDesign from '../../assets/images/Design_system.png';
import DashboardMobility from '../../assets/images/Mobility_modern.png';
import DashboardUnified from '../../assets/images/Unified_experience.png';
import DashboardOnline from '../../assets/images/Online_offline.png';
import HubspotForm from 'react-hubspot-form';
import rotateImage from "../../assets/images/Rotate.png";

class CardWP extends Component {
  constructor() {
    super();
      this.state = {
      isFlipped: false
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();    
    this.setState(prevState => ({ isFlipped: !prevState.isFlipped }));
  }

  whitePapersArray = [
    {
      id: 1,      
      cover: DashboardMeasure,
      portalId: '2208386',
      formId: '45ec6df2-466c-4088-b425-e6c25a8b82a9'
    },
    {
      id: 2,      
      cover: DashboardEnsure,
      portalId: '2208386',
      formId: '2c95dba5-2e20-47b2-bf78-123bd2de08c5'
    },
    {
      id: 3,      
      cover: DashboardDesign,
      portalId: '2208386',
      formId: 'b07a97f1-ce79-4e6f-9f96-86e52ed3ee2d'
    },
    {
      id: 4,      
      cover: DashboardMobility,
      portalId: '2208386',
      formId: 'e3a9751f-2399-4962-adfb-103a2f2a80a9'
    },
    {
      id: 5,      
      cover: DashboardUnified,
      portalId: '2208386',
      formId: '69f840bc-8e85-4807-86ce-19d60cb3c023'
    },
    {
      id: 6,      
      cover: DashboardOnline,
      portalId: '2208386',
      formId: 'b49b8e82-7c1e-4075-89cd-fe212e0a6b50'
    }
  ];

  render() {
    const whitePaper = this.props.whitePapers.find(item => item.id === this.props.wpId); // texto
    const whitePaperCov = this.whitePapersArray.find(item => item.id === this.props.wpId); //image
    const { title, description, backTitle } = whitePaper;
    const {  cover, portalId, formId   } = whitePaperCov;
    return(
      <ReactCardFlip isFlipped={this.state.isFlipped} flipDirection="horizontal">
        <div className="cardWPWrapper" onClick={this.handleClick} key="front">
          <div className={`cover`}></div>
          <img className={`dashboard`} alt={`Shockoe Mobile - ROI`} src={cover}/>
          <img className="dotgrid" alt={`Shockoe Mobile - ${title}`} height="56" src={DoGrid}/>
          <Container style={{paddingLeft: '16px', paddingRight: '16px'}}>
            <h3 className={`title ${getLanguage()}`}>{title}</h3>
            <p className={`description ${getLanguage()}`}>{description}</p>
          </Container>
          <img className="cardWPRotateRight" src={ rotateImage } alt="" />
        </div>
        <div className="cardWPWrapper" key="back">
          <Container style={{paddingLeft: '16px', paddingRight: '16px', paddingTop: '16px'}}>
            <h3 className={`title ${getLanguage()}`} onClick={this.handleClick}>{backTitle}</h3>
            <p className={`description ${getLanguage()}`} onClick={this.handleClick}>
              {this.props.emailDesc}
            </p>
            <Row>
              <Col>
              <div style={{ marginTop:'48px' }}>
                  <HubspotForm
                  portalId={portalId}
                  formId={formId}                                    
                  loading={<div>Loading...</div>} 
                  css=""                    
                  />
              </div>  
              </Col>
            </Row>                
          </Container>                    
          <div onClick={this.handleClick} className='invisibleDiv' />                  
          <div className="footer" onClick={this.handleClick}></div>
        </div>
      </ReactCardFlip>
    );
  }
}

export default CardWP;
