import React from 'react';
import './Products.css';
import { getLanguage } from 'react-switch-lang';

class Products extends React.Component {

  screenValues = [
    {                        
        backgroundColor : '#F46C00'
    },
    {                        
        backgroundColor : '#667175'
    },
    {                        
        backgroundColor : '#532E61'
    }
  ];

  lastLi(item){
    if(item){
      return <li>{ item }</li>;
    }
  }

  render() {
    return(
      <div style={{ backgroundColor: this.screenValues[this.props.index].backgroundColor }} className={`productWrapper ${getLanguage()}`} id="productComponent">
        <h2 className={`productTitle ${getLanguage()}`}>{ this.props.texts[this.props.index].title }</h2>
        {this.props.texts[this.props.index].text ? 
          <p className="productTitle-p">{ this.props.texts[this.props.index].text }</p>:
          <p className="productTitle-p">{ this.props.texts[this.props.index].text1 } <span>{ this.props.texts[this.props.index].textspan }</span> { this.props.texts[this.props.index].text2 }</p>
        }
        {this.props.texts[this.props.index].teamText ? 
          <p className="productTitle-p">{ this.props.texts[this.props.index].teamText }</p>:
          <p className="productTitle-p">{ this.props.texts[this.props.index].teamText1 } <span>{ this.props.texts[this.props.index].teamTextspan }</span> { this.props.texts[this.props.index].teamText2 }</p>
        }
        <ul className="productTitle-ul">
            
            {this.props.texts[this.props.index].l1 ? 
              <li>{ this.props.texts[this.props.index].l1 }</li>:
              <li>{ this.props.texts[this.props.index].l1_1 }<span>{ this.props.texts[this.props.index].l1_2 }</span>{ this.props.texts[this.props.index].l1_3 }</li>
            }
            <li>{ this.props.texts[this.props.index].l2 }</li>
            <li>{ this.props.texts[this.props.index].l3 }</li>   
            { this.lastLi(this.props.texts[this.props.index].l4) }                     
        </ul>
      </div>
    );
  }
}

export default Products;
