import React,{ Component } from 'react';
import './ProductSuccessDesktop.css';
import { getLanguage } from 'react-switch-lang';
import chevron from '../../assets/images/chevron.png'

class ProductSuccessDesktop extends Component {
  render() {
    return(
      <div className="productSuccessWrapper">
        <h2 className={`${getLanguage()}`}>{this.props.title}</h2>
        <p className={`textBold ${getLanguage()}`}><span>{this.props.texts[0].chartText[0]}</span>&nbsp;{this.props.texts[0].chartText[1]}&nbsp;{this.props.texts[0].chartText[2]}</p>
        <p className={`textNormal ${getLanguage()}`}>{ this.props.texts[0].text } <span>{ this.props.texts[0].span }</span>  { this.props.texts[0].secondText }</p>
        <div className="productCirclesWrapper">
          <hr/>
          <div className="productCircleContainer">
            <div className="productCircle">
              <div className="textCircleContainer" style={{top: '50%'}}>
                <p className="textCircle">
                  {this.props.texts[1].chartText[0]}
                </p>
                <p className="textCircle">
                  {this.props.texts[1].chartText[1]}
                </p>
                <p className="textCircle">
                  {this.props.texts[1].chartText[2]}
                </p>
                <p className="textCircle">
                  {this.props.texts[1].chartText[3]}
                </p>
              </div>
            </div>
            <div className="textFooterContainer">
              <p className={`textFooter ${getLanguage()}`}>{ this.props.texts[1].text } <span>{ this.props.texts[1].span }</span>  { this.props.texts[1].secondText }</p>
            </div>
          </div>
          <img className="chevronLeft" src={chevron} alt="Shockoe Mobile - Chevron"/>
          <div className="productCircleContainer">
            <div className="productCircle">
              <div className="textCircleContainer" style={{top: (getLanguage() === 'ar' ? '55%':'55%')}}>
                <p className="textCircle">
                  {this.props.texts[2].chartText[0]}
                </p>
                <p className="textCircle">
                  {this.props.texts[2].chartText[1]} {this.props.texts[2].chartText[2]}
                </p>
                <p className="textCircle">
                  {this.props.texts[2].chartText[3]}
                </p>
              </div>
            </div>
            <div className="textFooterContainer">
              <p className={`textFooter ${getLanguage()}`}>{ this.props.texts[2].text } <span>{ this.props.texts[2].span }</span>  { this.props.texts[2].secondText }</p>            
            </div>
          </div>
          <img className="chevronRight" src={chevron} alt="Shockoe Mobile - Chevron"/>
          <div className="productCircleContainer">
            <div className="productCircle">
              <div className="textCircleContainer">
                <p className="textCircle">
                  {this.props.texts[3].chartText[0]}
                </p>
                <p className="textCircle">
                  {this.props.texts[3].chartText[1]}
                </p>
                <p className="textCircle">
                  {this.props.texts[3].chartText[2]}
                </p>
                <p className="textCircle">
                  {this.props.texts[3].chartText[3]}
                </p>
              </div>
            </div>
            <div className="textFooterContainer">
              <p className={`textFooter ${getLanguage()}`}>{ this.props.texts[3].text } <span>{ this.props.texts[3].span }</span>  { this.props.texts[3].secondText }</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProductSuccessDesktop;