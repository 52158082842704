import React, {Component} from 'react';
import { Row, Col , Visible, Hidden } from 'react-grid-system';
import "./OurExpertise.css";
import inventoryImage from "../../assets/images/inventory_lg.png";
import inventoryImageDesktop from "../../assets/images/inventory_lg-desktop.png";
import workforceImage from "../../assets/images/workforce_lg.png";
import workforceImageDesktop from "../../assets/images/workforce_lg-desktop.png";
import rotateImage from "../../assets/images/Rotate.png";
import brandLoyaltyImage from "../../assets/images/brandLoyalty_lg.png";
import brandLoyaltyImageDesktop from "../../assets/images/brandLoyalty_lg-desktop.png";
import "react-alice-carousel/lib/alice-carousel.css";
import ReactCardFlip from 'react-card-flip';
import { getLanguage } from 'react-switch-lang';


class OurExpertise extends Component {       
    
    constructor() {
        super();
          this.state = {
          isFlipped: false,
          isFlipped2: false,
          isFlipped3: false
        };        
        this.handleClick = this.handleClick.bind(this);        
      }

    screenValues = [
        {
            image : inventoryImage,
            imageDesktop : inventoryImageDesktop
        },
        {
            image : workforceImage,
            imageDesktop : workforceImageDesktop
        },
        {
            image : brandLoyaltyImage,
            imageDesktop : brandLoyaltyImageDesktop
        }
    ];

    handleClick(e) {        
        e.preventDefault();
        this.setState(prevState => ({ isFlipped: !prevState.isFlipped }));
      }

    showTapText(){
        if(this.props.tapText){
            return (
            <Row justify="center">
                <Col>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '25px' }}>
                        <h2 className="expertiseRotateText">{ this.props.tapText }</h2>
                        <img className="expertiseRotate" src={ rotateImage } alt="" />
                    </div>                    
                </Col>                    
            </Row> );
        }
    }

    getWidthClass(){
        if(window.innerWidth < 1024){
            return 'breakText';
        }else{
            return 'breakTextDesktop';
        }
    }

    render() {               
        return(
            <ReactCardFlip isFlipped={this.state.isFlipped} flipDirection="horizontal">
                <div className="cardExpertise" key="front" onClick={this.handleClick}>
                    <Row justify="center" style={{paddingTop: '70px'}}>
                        <Col sm={12}>
                        <Visible sm xs md>
                            <img className="expertiseImg" src={ this.screenValues[this.props.item].image } alt={`Shockoe Mobile - ${this.props.textFront}`} />
                        </Visible>
                        <Hidden sm xs md>
                            <img className="expertiseImg" src={ this.screenValues[this.props.item].imageDesktop } alt={`Shockoe Mobile - ${this.props.textFront}`} />
                        </Hidden>
                        </Col>                  
                    </Row>
                    <Row justify="center">
                        <Col sm={12}>
                            <div className={`expertiseFrontContainer ${this.props.item === 1 ? 'short':'' }`}>
                                <h2 className={`expertiseText ${this.props.item === 2 ? this.getWidthClass():'' } `}>{ this.props.textFront }</h2>
                            </div>
                        </Col>                  
                    </Row> 
                    {this.showTapText()}
                </div>
                <div className="cardExpertise" style={{ backgroundColor:'#F2F3F4' }} key="back" onClick={this.handleClick}>                    
                    <Row justify="center">
                        <Col sm={12}>
                            <div className="expertiseBackContainer">
                                <h2 className={`expertiseText ${getLanguage()}`} >{ this.props.textBack }</h2>
                            </div>
                        </Col>                  
                    </Row>  
                </div>
            </ReactCardFlip>  
        );
      }  
}

export default OurExpertise;