import React, {Component} from 'react';
import './Card.css';
import ReactCardFlip from 'react-card-flip';
import { Container } from 'react-grid-system';
import { getLanguage } from 'react-switch-lang';
import ArrowLogo from '../../assets/images/Arrow_Electronics_logo.png';
import DashboardArrow from '../../assets/images/Dashboard_arrow.png';
import OneokLogo from '../../assets/images/Oneok_logo.png';
import DashboardOneok from '../../assets/images/Dashboard_oneok.png';
import JBHuntLogo from '../../assets/images/Jb_hunt_logo.png';
import DashboardJBHunt from '../../assets/images/Dashboard_jb_hunt.png';
import AcmLogo from '../../assets/images/Acm_logo.png';
import DashboardAcm from '../../assets/images/Dashboard_acm.png';
import VACULogo from '../../assets/images/Vacu_logo.png';
import DashboardVACU from '../../assets/images/Dashboard_vacu.png';
import WinnLogo from '../../assets/images/Winn_logo.png';
import DashboardWinn from '../../assets/images/Dashboard_winn.png';
import AWLogo from '../../assets/images/American_woodmark_logo.png';
import DashboardAW from '../../assets/images/Dashboard_aw.png';
import ValactaLogo from '../../assets/images/Valacta_logo.png';
import DashboardValacta from '../../assets/images/Dashboard_valacta.png';
import FarmersLogo from '../../assets/images/Farmers_logo.png';
import DashboardFarmers from '../../assets/images/Dashboard_farmers.png';
import DominionLogo from '../../assets/images/Dominion_logo.png';
import DashboardDominion from '../../assets/images/Dashboard_dominion.png';
import FloorcoLogo from '../../assets/images/Floorco_logo.png';
import DashboardFloorco from '../../assets/images/FlooringCo.gif';
import PlayartLogo from '../../assets/images/Playart_logo.png';
import DashboardPlayart from '../../assets/images/PlayARt.gif';

import DashboardSkafos from '../../assets/images/Bootfinder.gif';
import SkafosLogo from '../../assets/images/Skafos_logo.png';

import ShockoePhone from '../../assets/images/Phone.png'
import DoGrid from '../../assets/images/DotGrid.png';
import DoGridGrayMin from '../../assets/images/DotGrid_gray_min.png';
import DoGridGray from '../../assets/images/DotGrid_gray.png';

import rotateImage from "../../assets/images/Rotate.png";

const { detect } = require('detect-browser');


class Card extends Component {
  constructor() {
    super();
      this.state = {
      isFlipped: false
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    this.setState(prevState => ({ isFlipped: !prevState.isFlipped }));
  }

  productsArray = [
    {     
      brand: "Arrow", 
      dashboard: DashboardArrow,
      logo: ArrowLogo,
      cover: "cover--0",
      color: "#F77F2B",
      type: "tablet",
      orientation: "right",
      heightLogo: 24
    },
    {      
      brand: "Oneok",
      dashboard: DashboardOneok,
      logo: OneokLogo,
      cover: "cover--1",
      color: "#00AEEF",
      type: "tablet",
      orientation: "right",
      heightLogo: 24
    },
    {      
      brand: "JBHunt",
      dashboard: DashboardJBHunt,
      logo: JBHuntLogo,
      cover: "cover--2",
      color: "#202121",
      type: "phone",
      orientation: "left",
      heightLogo: 24
    },
    {      
      brand: "ACMoore",
      dashboard: DashboardAcm,
      logo: AcmLogo,
      cover: "cover--3",
      color: "#B1081D",
      type: "phone",
      orientation: "left",
      heightLogo: 24
    },
    {      
      brand: "Vacu",
      dashboard: DashboardVACU,
      logo: VACULogo,
      cover: "cover--4",
      color: "#003A70",
      type: "phone",
      orientation: "left",
      heightLogo: 24
    },
    {      
      brand: "Winn",
      dashboard: DashboardWinn,
      logo: WinnLogo,
      cover: "cover--5",
      color: "#2F880F",
      type: "phone",
      orientation: "left",
      heightLogo: 24
    },
    {      
      brand: "American",
      dashboard: DashboardAW,
      logo: AWLogo,
      cover: "cover--6",
      color: "#FF0159",
      type: "phone",
      orientation: "left",
      heightLogo: 24
    },
    {
      brand: "Valacta",
      dashboard: DashboardValacta,
      logo: ValactaLogo,
      cover: "cover--7",
      color: "#02BEE1",
      type: "phone",
      orientation: "left",
      heightLogo: 47
    },
    { 
      brand: "Farmers",
      dashboard: DashboardFarmers,
      logo: FarmersLogo,
      cover: "cover--8",
      color: "#E31837",
      type: "phone",
      orientation: "left",
      heightLogo: 38
    },
    {      
      brand: "Dominion",
      dashboard: DashboardDominion,
      logo: DominionLogo,
      cover: "cover--9",
      color: "#1C68AC",
      type: "phone",
      orientation: "left",
      heightLogo: 21
    },
    {      
      brand: "Floorco",
      dashboard: DashboardFloorco,
      logo: FloorcoLogo,
      cover: "cover--10",
      color: "#532E61",
      type: "phone-gif",
      orientation: "left",
      heightLogo: 24
    },
    {      
      brand: "Playart",
      dashboard: DashboardPlayart,
      logo: PlayartLogo,
      cover: "cover--11",
      color: "#000000",
      type: "phone-gif",
      orientation: "left",
      heightLogo: 48
    },
    {      
      brand: "Skafos",
      dashboard: DashboardSkafos,
      logo: SkafosLogo,
      cover: "cover--12",
      color: "#686EE0",
      type: "phone-gif",
      orientation: "left",
      heightLogo: 24
    }
  ]


  smallText(brand){
    if(getLanguage() === 'ar'){
      switch (brand) {
        case "JBHunt":
        case "Floorco":                  
        case "Playart":                  
        case "Skafos":                  
          return 'small';          
        default:
          return '';
      }
    }
    return '';
    
  }

  invisibleCard(front){
    const browser = detect();
    if( browser.name === 'ios' && browser.os === 'iOS' &&  parseInt(browser.version.substr(0, 2)) < 13 ){
      if(front){
        return this.state.isFlipped ? 'hidden' : '';
      }else{
        return !this.state.isFlipped ? 'hidden' : '';
      }
    }else{
      return '';
    }
  }

  render() {
    const product = this.productsArray.find(item => item.brand === this.props.product);
    const productText = this.props.products.find(item => item.brand === this.props.product);
    const { brand, cover, dashboard, logo, type, color, orientation, heightLogo} = product;
    const { description, infoTitle, infoDescription, solution, points, quote } = productText;

    return(
      <ReactCardFlip className="smallScreenFlip" isFlipped={this.state.isFlipped} flipDirection="horizontal">
        <div className="cardWrapper" style={{ visibility : this.invisibleCard(true) }} onClick={this.handleClick} key="front">
          <div className={`cover ${cover}`}></div>
          {type === 'phone-gif' ? <img className="phoneCover" alt={`Shockoe Mobile - ShockoePhone`} height="213" width="109" src={ShockoePhone}/>:''}
          <img className={`dashboard-${type}`} alt={`Shockoe Mobile - ${brand}`} src={dashboard}/>
          <img className="dotgrid" alt={`Shockoe Mobile - ${brand}`} height="56" src={DoGrid}/>
          <Container>
            <img className={`logo ${heightLogo > 24 ? 'long':''}`} alt="Shockoe Mobile - DoGrid" height={heightLogo} src={logo}/>
            <p className={`${getLanguage()}`}>{description}</p>
          </Container>
          <div className={`infoContainer ${orientation}`}>
            <div className={`info ${getLanguage()} ${this.smallText(brand)}`} style={{background: color}}>
              <Container>
                <h3>{infoTitle}</h3>
                <p>{infoDescription}</p>
              </Container>
            </div>
          </div>
          <img className="dotgrid-footer" alt="Shockoe Mobile - DoGrid" height="56" src={DoGrid}/>
          <img className={ ( brand === 'Arrow' || brand === 'Oneok' ) ? "cardRotateLeft" : "cardRotateRight" } src={ rotateImage } alt="" />
        </div>

        <div className="cardWrapper" style={{ visibility : this.invisibleCard(false) }} onClick={this.handleClick} key="back">
          <Container>
            <img className="logo-back" alt={`Shockoe Mobile - ${brand}`} height={heightLogo} src={logo}/>
          </Container>
          <img className="dotgrid-back" alt="Shockoe Mobile - DoGrid" height="35.25" src={DoGridGrayMin}/>
          <Container>
            <p className={`${getLanguage()}`}>
              <span>{this.props.solutionText}</span><br/>
              {solution}
            </p>
            <ul className={`${getLanguage()}`}>
              {points.map((item, key) => {
                return <li key={key}>{item}</li>
              })}
            </ul>
          </Container>
          <div className="dotgridContainer">
            <img className="dotgrid-back-footer" alt="Shockoe Mobile - DoGrid" height="35.25" src={DoGridGray}/>
          </div>
          <Container>
            <p className={`quote ${getLanguage()}`}>{quote}</p>
          </Container>
          <div className="footer" style={{background: color}}></div>          
        </div>
      </ReactCardFlip>
    );
  }
}

export default Card;
