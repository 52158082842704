import React, { Component } from 'react';
import './AddHome.css';
import { getLanguage } from 'react-switch-lang';

class AddHome extends Component {
  render() {
    return (
      <div className="promptContainer">
        <p className={`${getLanguage()}`}>{this.props.promptText.description}</p>
        <div className="contentButtons">
          <button type="button" className={`${getLanguage()}`} onClick={this.props.handleClose}>{this.props.promptText.cancel}</button>
          <button type="button" className={`${getLanguage()}`} onClick={this.props.installApp}>{this.props.promptText.add}</button>
        </div>
      </div>
    );
  }
}

export default AddHome;