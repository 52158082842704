import React, {Component} from 'react';
import './ProblemsSolve.css';
import { Container ,Row, Col, Visible, Hidden } from 'react-grid-system';
import AssetManagement from '../../assets/images/Asset_Management.png'
import AssetManagementDesktop from '../../assets/images/Asset_Management-desktop.png';
import ConfidentPurchases from '../../assets/images/confident_purchases.png';
import ConfidentPurchasesDesktop from '../../assets/images/confident_purchases-desktop.png';
import EmergingTechnologuy from '../../assets/images/Emerging_Technologuy.png';
import EmergingTechnologuyDesktop from '../../assets/images/Emerging_Technologuy-desktop.png';
import EmployeeEfficiency from '../../assets/images/employee_efficiency.png';
import EmployeeEfficiencyDesktop from '../../assets/images/employee_efficiency-desktop.png';
import QualityControl from '../../assets/images/quality_control.png';
import QualityControlDesktop from '../../assets/images/quality_control-desktop.png';
import RetainingCustomers from '../../assets/images/Retaining_Customers.png';
import RetainingCustomersDesktop from '../../assets/images/Retaining_Customers-desktop.png';
import DoGrid from '../../assets/images/DotGrid.png';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { getLanguage } from 'react-switch-lang';


class ProblemsSolve extends Component {

  render() {
    return(
      <div className="problemsWrapper">
        <div className="problemsContainer">
          <Container>
            <h3 className={`${getLanguage()}`}>{ this.props.texts.title }</h3>
            <Row justify="center" className="customRow">
              <Col xs={4} style={{textAlign: 'center'}} className="customCol">
                <AnchorLink href='#Asset'>
                  <Visible xs sm md>
                    <img alt="Shockoe Mobile - Asset Management" height="64" width="64" src={AssetManagement}/>
                  </Visible>
                  <Hidden xs sm md>
                    <img alt="Shockoe Mobile - Asset Management" height="120" width="120" src={AssetManagementDesktop}/>
                  </Hidden>
                  <div className="contentText">
                    <p className={`${getLanguage()}`}>{ this.props.texts.asset }</p>
                  </div>
                </AnchorLink>
              </Col>
              <Col xs={4} style={{textAlign: 'center'}} className="customCol">
                <AnchorLink href="#Employee">
                  <Visible xs sm md>
                    <img alt="Shockoe Mobile - Employee Efficiency" height="64" width="64" src={EmployeeEfficiency}/>                    
                  </Visible>
                  <Hidden xs sm md>
                    <img alt="Shockoe Mobile - Employee Efficiency" height="120" width="120" src={EmployeeEfficiencyDesktop}/>                    
                  </Hidden>
                  <div className="contentText">
                    <p className={`${getLanguage()}`}>{ this.props.texts.employee }</p>
                  </div>
                </AnchorLink>
              </Col>
              <Col xs={4} style={{textAlign: 'center'}} className="customCol">
                <AnchorLink href="#Quality">
                  <Visible xs sm md>
                    <img alt="Shockoe Mobile - Quality Control" height="64" width="64" src={QualityControl}/>                                    
                  </Visible>
                  <Hidden xs sm md>
                    <img alt="Shockoe Mobile - Quality Control" height="120" width="120" src={QualityControlDesktop}/>                             
                  </Hidden>
                  <div className="contentText">
                    <p className={`${getLanguage()}`}>{ this.props.texts.quality }</p>
                  </div>
                </AnchorLink>
              </Col>
            </Row>
            <Row justify="center" className="customRow">
              <Col xs={4} style={{textAlign: 'center'}} className="customCol">
                <AnchorLink href="#Retaining">
                  <Visible xs sm md>
                    <img alt="Shockoe Mobile - Retaining Customers" height="64" width="64" src={RetainingCustomers}/>                                                     
                  </Visible>
                  <Hidden xs sm md>
                    <img alt="Shockoe Mobile - Retaining Customers" height="120" width="120" src={RetainingCustomersDesktop}/>                                          
                  </Hidden>
                  <div className="contentText">
                    <p>{ this.props.texts.retaining }</p>
                  </div>
                </AnchorLink>
              </Col>
              <Col xs={4} style={{textAlign: 'center'}} className="customCol">
                <AnchorLink href="#Improving">
                  <Visible xs sm md>
                    <img alt="Shockoe Mobile - Confident Purchases" height="64" width="64" src={ConfidentPurchases}/>                                                              
                  </Visible>
                  <Hidden xs sm md>
                    <img alt="Shockoe Mobile - Confident Purchases" height="120" width="120" src={ConfidentPurchasesDesktop}/>                                                          
                  </Hidden>
                  <div className="contentText">
                    <p>{ this.props.texts.confident }</p>                    
                  </div>
                </AnchorLink>
              </Col>
              <Col xs={4} style={{textAlign: 'center'}} className="customCol">
                <AnchorLink href="#Emerging">
                  <Visible xs sm md>
                    <img alt="Shockoe Mobile - Emerging Technology" height="64" width="64" src={EmergingTechnologuy}/>                                                                        
                  </Visible>
                  <Hidden xs sm md>
                    <img alt="Shockoe Mobile - Emerging Technology" height="120" width="120" src={EmergingTechnologuyDesktop}/>                    
                  </Hidden>
                  <div className="contentText">
                    <p>{ this.props.texts.emerging }</p>
                  </div>
                </AnchorLink>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="problemFooter">
          <Container>
            <h4 className={`problemsSubtitle ${getLanguage()}`}>{ this.props.texts.where } { this.props.texts.span }</h4>
          </Container>
          <Row justify="end">
            <Col xs={11} style={{textAlign: 'end'}}>
              <img alt="Shockoe Mobile - Scroll" height="40" src={DoGrid}/>
            </Col>
          </Row>
        </div>
      </div>
    );
  }  
}

export default ProblemsSolve;