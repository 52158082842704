import React, {Component} from "react";
import "./Home.css";
import Conditional from 'react-simple-conditional';
import { Row, Col, Container, Visible, Hidden } from 'react-grid-system';
import LogoShockoe from '../../assets/images/Shockoe-Logo.png';
import LogoShockoeDesktop from '../../assets/images/Shockoe-Logo-Desktop.png';
import Peeps from '../../assets/images/peeps.png';
import PeepsDesktop from '../../assets/images/peeps-desktop.png';
import ScrollHelp from '../../assets/images/Scroll_down_arrows.png';
import ProblemsSolve from '../../components/ProblemsSolve/ProblemsSolve';
import Card from '../../components/Card/Card';
import CardWP from '../../components/CardWP/CardWP';
import Carousel from '../../components/Carousel/Carousel';
import Footer from '../../components/footer/footer';
import Menu from '../../components/MenuDesktop/MenuDesktop';
import OurExpertise from '../../components/ourExpertise/OurExpertise';
import Strategy from '../../components/strategy/Strategy';
import Products from '../../components/Products/Products';
import ProductSuccess from '../../components/pruductSuccess/productSuccess';
import ProductSuccessDesktop from '../../components/ProductSuccessDesktop/ProductSuccessDesktop';
import ContactUs from '../../components/ContactUs/ContactUs';
import AddHome from '../../components/AddHome/AddHome';
import AnchorLink from 'react-anchor-link-smooth-scroll'

// Translation Higher Order Component
import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
  translate,
  getLanguage,
} from 'react-switch-lang';
import en from '../../languages/en.json';
import ar from '../../languages/ar.json';

// Do this two lines only when setting up the application
setTranslations({ en, ar });
setDefaultLanguage('en');

// If you want to remember selected language
setLanguageCookie();
 

class Home extends Component {
  constructor() {
    super();
    this.state = {        
      visibleHomeFooter : true,
      width: 0,
      paddingLeft: 24,
      paddingRight: 44,
      paddingLeftExp: 64,
      paddingRightExp: 34      
    }; 
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);           
  }  

  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll);
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);    
  }
    
  componentWillUnmount() {
      window.removeEventListener('scroll', this.listenToScroll);
      window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {    
    if( this.state.width !==  window.innerWidth  ){
      this.setState({ width: window.innerWidth });  
    switch (window.innerWidth) {
      case 320:
        this.setState({ paddingLeft: 0, paddingRight: 0, paddingLeftExp: 14, paddingRightExp: 14 });
        break;
      case 360:
        this.setState({ paddingLeft: -24, paddingRight: 14, paddingLeftExp: 34, paddingRightExp: 24 });
        break;
      case 375:
        this.setState({ paddingLeft: -30, paddingRight: 20, paddingLeftExp: 44, paddingRightExp: 24 });
        break;
      case 568: //portrait
        this.setState({ paddingLeftExp: 140, paddingRightExp: 60 });
        break;
      case 640:
        this.setState({ paddingLeftExp: 170, paddingRightExp: 60 });
        break;
      case 667:
        this.setState({ paddingLeftExp: 190, paddingRightExp: 60 });
        break;
      case 731:
        this.setState({ paddingLeftExp: 220, paddingRightExp: 60 });
        break;  
      case 736:
        this.setState({ paddingLeftExp: 220, paddingRightExp: 60 });
        break;
      case 1024:
        this.setState({ paddingLeft: 12, paddingRight: 12 });
        break;
      case 1152:
        this.setState({ paddingLeft: 75, paddingRight: 75 });
        break;
      case 1280:
        this.setState({ paddingLeft: 140, paddingRight: 140 });
        break;
      case 1366:
        this.setState({ paddingLeft: 180, paddingRight: 180 });
        break;
      case 1440:
        this.setState({ paddingLeft: 220, paddingRight: 220 });
        break;
      case 1536:
        this.setState({ paddingLeft: 265, paddingRight: 265 });
        break;
      case 1600:
        this.setState({ paddingLeft: 300, paddingRight: 300 });
        break;
      case 1630:
        this.setState({ paddingLeft: 320, paddingRight: 320 });
        break;
      case 1680:
        this.setState({ paddingLeft: 343, paddingRight: 343 });
        break;
      case 1730:
        this.setState({ paddingLeft: 365, paddingRight: 365 });
        break;
      case 1800:
        this.setState({ paddingLeft: 400, paddingRight: 400 });
        break;
      case 1920:
        this.setState({ paddingLeft: 460, paddingRight: 460 });
        break;
      case 2560:
        this.setState({ paddingLeft: 780, paddingRight: 780 });
        break;
      default:
        if(window.innerWidth > 375 && window.innerWidth <411)  {
          this.setState({ paddingLeft: -24, paddingRight: 20, paddingLeftExp: 44, paddingRightExp: 24 });
        }else if(window.innerWidth > 1024 && window.innerWidth <1152)  {
          this.setState({ paddingLeft: 30, paddingRight: 30 });
        }else if(window.innerWidth > 1152 && window.innerWidth <1280)  {
          this.setState({ paddingLeft: 105, paddingRight: 105 });
        }else if(window.innerWidth > 1280 && window.innerWidth <1360)  {
          this.setState({ paddingLeft: 160, paddingRight: 160 });
        }else if(window.innerWidth > 1359 && window.innerWidth <1366)  {
          this.setState({ paddingLeft: 180, paddingRight: 180 });
        }else if(window.innerWidth > 1366 && window.innerWidth <1440)  {
          this.setState({ paddingLeft: 195, paddingRight: 195 });
        }else if(window.innerWidth > 1440 && window.innerWidth <1536)  {
          this.setState({ paddingLeft: 240, paddingRight: 240 });
        }else if(window.innerWidth > 1536 && window.innerWidth <1600)  {
          this.setState({ paddingLeft: 285, paddingRight: 285 });
        }else if(window.innerWidth > 1600 && window.innerWidth <1630)  {
          this.setState({ paddingLeft: 315, paddingRight: 315 });
        }else if(window.innerWidth > 1630 && window.innerWidth <1680)  {
          this.setState({ paddingLeft: 335, paddingRight: 335 });
        }else if(window.innerWidth > 1680 && window.innerWidth <1730)  {
          this.setState({ paddingLeft: 355, paddingRight: 355 });
        }else if(window.innerWidth > 1730 && window.innerWidth <1800)  {
          this.setState({ paddingLeft: 385, paddingRight: 385 });
        }else if(window.innerWidth > 1800 && window.innerWidth <1920)  {
          this.setState({ paddingLeft: 435, paddingRight: 435 });
        }else if(window.innerWidth > 1920 && window.innerWidth <2560)  {
          this.setState({ paddingLeft: 505, paddingRight: 505 });
        }else {
          this.setState({ paddingLeft: -40, paddingRight: 44, paddingLeftExp: 64, paddingRightExp: 34 });
        }
         break;
    }
    }
    
  }

  listenToScroll = () => {              
      const scrollValue = document.scrollingElement || document.documentElement;       
      if(scrollValue.scrollTop > 220 && this.state.visibleHomeFooter){            
        this.setState({
          visibleHomeFooter : false
        })        
      }else if (scrollValue.scrollTop < 210 && !this.state.visibleHomeFooter){         
        this.setState({
          visibleHomeFooter : true
        })        
      }       
  }

  render() {

    const { t } = this.props;

    const OurExpertiseScreenValues = [
      {        
          text : t('expertise.inventory'),
          textBack : t('expertise.inventoryBack'),
          tap: window.innerWidth >= 1024 ? t('expertise.click') : t('expertise.tap')
      },
      {        
          text : t('expertise.mobile'),
          textBack : t('expertise.mobileBack'),
          tap: window.innerWidth >= 1024 ? t('expertise.click') : t('expertise.tap')
      },
      {        
          text : t('expertise.customer'),
          textBack : t('expertise.customerBack'),
          tap: window.innerWidth >= 1024 ? t('expertise.click') : t('expertise.tap')
      }
    ];

    const StrategyscreenValues = [
      {            
          title : t('strategy.strategy.title'),
          text: t('strategy.strategy.text'),
          teamText: t('strategy.strategy.teamText'),
          l1_1: t('strategy.strategy.l1_1'),
          l1_2: t('strategy.strategy.l1_2'),
          l1_3: t('strategy.strategy.l1_3'),
          l2: t('strategy.strategy.l2'),
          l3: t('strategy.strategy.l3')
      },
      {            
          title : t('strategy.design.title'),
          text1: t('strategy.design.text1'),
          textspan: t('strategy.design.textspan'),
          text2: t('strategy.design.text2'),
          teamText: t('strategy.design.teamText'),
          l1: t('strategy.design.l1'),
          l2: t('strategy.design.l2'),       
          l3: t('strategy.design.l3')
      },
      {            
          title : t('strategy.development.title'),
          text: t('strategy.development.text'),
          teamText1: t('strategy.development.teamText1'),
          teamTextspan: t('strategy.development.teamTextspan'),
          teamText2: t('strategy.development.teamText2'),
          l1: t('strategy.development.l1'),
          l2: t('strategy.development.l2'),       
          l3: t('strategy.development.l3'),
          l4: t('strategy.development.l4')
      }
    ]; 

    const ProductSuccessScreenValues =[        
        {                        
          text: t('productSuccess.step1.text'),
          span: t('productSuccess.step1.span'),
          secondText: t('productSuccess.step1.secondText'),       
          chartText: [
              t('productSuccess.step1.chartText.text1'),
              t('productSuccess.step1.chartText.text2'),
              t('productSuccess.step1.chartText.text3'),
          ]          
      },
      {                        
          text: t('productSuccess.step2.text'),
          span: t('productSuccess.step2.span'),
          secondText: t('productSuccess.step2.secondText'),                 
          chartText: [
              t('productSuccess.step2.chartText.text1'),
              t('productSuccess.step2.chartText.text2'),
              t('productSuccess.step2.chartText.text3'),
              t('productSuccess.step2.chartText.text4'),
          ]          
      },
      {                        
          text: t('productSuccess.step3.text'),
          span: t('productSuccess.step3.span'),
          secondText: t('productSuccess.step3.secondText'),  
          chartText: [
              t('productSuccess.step3.chartText.text1'),
              t('productSuccess.step3.chartText.text2'),
              t('productSuccess.step3.chartText.text3'),              
          ]          
      },
      {                        
          text: t('productSuccess.step4.text'),
          span: t('productSuccess.step4.span'),
          secondText: t('productSuccess.step4.secondText'),   
          chartText: [
              t('productSuccess.step4.chartText.text1'),
              t('productSuccess.step4.chartText.text2'),
              t('productSuccess.step4.chartText.text3')            
          ]          
      }
    ];

    const ProblemsSolveValues = {
      title: t('problemsSolve.title'),      
      asset: t('problemsSolve.asset'),
      employee: t('problemsSolve.employee'),
      quality: t('problemsSolve.quality'),
      retaining: t('problemsSolve.retaining'),
      confident: t('problemsSolve.confident'),
      emerging: t('problemsSolve.emerging'),
      where: t('problemsSolve.where'),
      span: t('problemsSolve.span'),
    }

    const cardProducts = [
      {
        brand: "Arrow",
        description: t('cards.products.arrow.description'),
        solution: t('cards.products.arrow.solution'),
        infoTitle: t('cards.products.arrow.infoTitle'),
        infoDescription:  t('cards.products.arrow.infoDescription'),
        points: [
          t('cards.products.arrow.points.p1'),
          t('cards.products.arrow.points.p2'),
          t('cards.products.arrow.points.p3')
        ],
        quote: t('cards.products.arrow.quote'),        
      },
      {
        brand: "Oneok",
        description: t('cards.products.oneok.description'),
        solution: t('cards.products.oneok.solution'),
        infoTitle: t('cards.products.oneok.infoTitle'),
        infoDescription: t('cards.products.oneok.infoDescription'),
        points: [],
        quote: t('cards.products.oneok.quote'),        
      },
      {
        brand: "JBHunt",
        description: t('cards.employee.jbhunt.description'),
        solution: t('cards.employee.jbhunt.solution'),
        infoTitle: t('cards.employee.jbhunt.infoTitle'),
        infoDescription: t('cards.employee.jbhunt.infoDescription'),
        points: [
          t('cards.employee.jbhunt.points.p1'),
          t('cards.employee.jbhunt.points.p2'),
          t('cards.employee.jbhunt.points.p3'),
          t('cards.employee.jbhunt.points.p4')
        ],
        quote: t('cards.employee.jbhunt.quote'),
      },
      {
        brand: "ACMoore",
        description: t('cards.employee.acmoore.description'),
        solution: t('cards.employee.acmoore.solution'),
        infoTitle: t('cards.employee.acmoore.infoTitle'),
        infoDescription: t('cards.employee.acmoore.infoDescription'),
        points: [
          t('cards.employee.acmoore.points.p1'),
          t('cards.employee.acmoore.points.p2'),
          t('cards.employee.acmoore.points.p3'),
          t('cards.employee.acmoore.points.p4')
        ],
        quote: t('cards.employee.acmoore.quote'),
      },
      {
        brand: "Vacu",
        description: t('cards.retaining.vacu.description'),
        solution: t('cards.retaining.vacu.solution'),
        infoTitle: t('cards.retaining.vacu.infoTitle'),
        infoDescription: t('cards.retaining.vacu.infoDescription'),
        points: [
          t('cards.retaining.vacu.points.p1'),
          t('cards.retaining.vacu.points.p2'),
          t('cards.retaining.vacu.points.p3'),
          t('cards.retaining.vacu.points.p4'),
        ],
        quote: t('cards.retaining.vacu.quote'),
      },
      {
        brand: "Winn",
        description: t('cards.retaining.winn.description'),
        solution: t('cards.retaining.winn.solution'),
        infoTitle: t('cards.retaining.winn.infoTitle'),
        infoDescription: t('cards.retaining.winn.infoDescription'),
        points: [
          t('cards.retaining.winn.points.p1'),
          t('cards.retaining.winn.points.p2'),
          t('cards.retaining.winn.points.p3'),
        ],
        quote: t('cards.retaining.winn.quote'),
      },
      {
        brand: "American",
        description: t('cards.quality.american.description'),
        solution: t('cards.quality.american.solution'),
        infoTitle: t('cards.quality.american.infoTitle'),
        infoDescription: t('cards.quality.american.infoDescription'),
        points: [
          t('cards.quality.american.points.p1'),
        ],
        quote: t('cards.quality.american.quote'),
      },
      {
        brand: "Valacta",
        description: t('cards.quality.valacta.description'),
        solution: t('cards.quality.valacta.solution'),
        infoTitle: t('cards.quality.valacta.infoTitle'),
        infoDescription: t('cards.quality.valacta.infoDescription'),
        points: [
          t('cards.quality.valacta.points.p1'),
          t('cards.quality.valacta.points.p2'),
          t('cards.quality.valacta.points.p3')
        ],
        quote: t('cards.quality.valacta.quote'),
      },
      {
        brand: "Farmers",
        description: t('cards.confident.farmers.description'),
        solution: t('cards.confident.farmers.solution'),
        infoTitle: t('cards.confident.farmers.infoTitle'),
        infoDescription: t('cards.confident.farmers.infoDescription'),
        points: [
          t('cards.confident.farmers.points.p1'),
          t('cards.confident.farmers.points.p2'),
          t('cards.confident.farmers.points.p3'),
          t('cards.confident.farmers.points.p4'),
        ],
        quote: t('cards.confident.farmers.quote'),
      },
      {
        brand: "Dominion",
        description: t('cards.confident.dominion.description'),
        solution: t('cards.confident.dominion.solution'),
        infoTitle: t('cards.confident.dominion.infoTitle'),
        infoDescription: t('cards.confident.dominion.infoDescription'),
        points: [
          t('cards.confident.dominion.points.p1'),
          t('cards.confident.dominion.points.p2'),
          t('cards.confident.dominion.points.p3'),
        ],
        quote: t('cards.confident.dominion.quote'),
      },
      {
        brand: "Floorco",
        description: t('cards.emerging.floorco.description'),
        solution: t('cards.emerging.floorco.solution'),
        infoTitle: t('cards.emerging.floorco.infoTitle'),
        infoDescription: t('cards.emerging.floorco.infoDescription'),
        points: [
          t('cards.emerging.floorco.points.p1'),
          t('cards.emerging.floorco.points.p2'),
          t('cards.emerging.floorco.points.p3'),
        ],
        quote: t('cards.emerging.floorco.quote'),
      },
      {
        brand: "Playart",
        description: t('cards.emerging.playart.description'),
        solution: t('cards.emerging.playart.solution'),
        infoTitle: t('cards.emerging.playart.infoTitle'),
        infoDescription: t('cards.emerging.playart.infoDescription'),
        points: [
          t('cards.emerging.playart.points.p1'),
          t('cards.emerging.playart.points.p2'),
        ],
        quote: t('cards.emerging.playart.quote'),
      },
      {
        brand: "Skafos",
        description: t('cards.emerging.skafos.description'),
        solution: t('cards.emerging.skafos.solution'),
        infoTitle: t('cards.emerging.skafos.infoTitle'),
        infoDescription: t('cards.emerging.skafos.infoDescription'),
        points: [
          t('cards.emerging.skafos.points.p1'),
          t('cards.emerging.skafos.points.p2'),
        ],
        quote: t('cards.emerging.skafos.quote'),
      }
    ]

    const whitePapers = [
      {
        id: 1,
        title: t('cards.industry.wp1.title'),
        description: t('cards.industry.wp1.description'),
        backTitle: t('cards.industry.wp1.backTitle'),
      },
      {
        id: 2,
        title: t('cards.industry.wp2.title'),
        description: t('cards.industry.wp2.description'),
        backTitle: t('cards.industry.wp2.backTitle'),
      },
      {
        id: 3,
        title: t('cards.industry.wp3.title'),
        description: t('cards.industry.wp3.description'),
        backTitle: t('cards.industry.wp3.backTitle'),
      },
      {
        id: 4,
        title: t('cards.industry.wp4.title'),
        description: t('cards.industry.wp4.description'),
        backTitle: t('cards.industry.wp4.backTitle'),
      },
      {
        id: 5,
        title: t('cards.industry.wp5.title'),
        description: t('cards.industry.wp5.description'),
        backTitle: t('cards.industry.wp5.backTitle'),        
      },
      {
        id: 6,
        title: t('cards.industry.wp6.title'),
        description: t('cards.industry.wp6.description'),
        backTitle: t('cards.industry.wp6.backTitle'),        
      }
    ];

    const menuText = {
      title:  t('menu.title'),
      asset:  t('menu.asset'),
      employee:  t('menu.employee'),
      quality:  t('menu.quality'),
      retaining:  t('menu.retaining'),
      improving:  t('menu.improving'),
      emerging:  t('menu.emerging'),
      contact:  t('menu.contact'),
      industry: t('menu.industry'),
      lanAr: t('menu.lanAr'),
      lanEn: t('menu.lanEn'),
    } 
    
    const promptText = {
      description:  t('prompt.description'),
      cancel:  t('prompt.cancel'),
      add:  t('prompt.add'),
    } 

    return (
      <div>
        <div id="Home" className="homeWrapper">
          <div className="homeContainer">
            <Hidden sm xs>
              <Menu menuText={menuText}/>
            </Hidden>
            <Row justify="center">
              <Col style={{textAlign: 'center'}}>
                <Visible sm xs md>
                  <img className="logo" alt="Shockoe Mobile - Logo" height="66" width="270" src={LogoShockoe}/>
                </Visible>
                <Hidden sm xs md>
                  <img className="logo-desktop" alt="Shockoe Mobile - Logo" height="193.79" width="792" src={LogoShockoeDesktop}/>
                </Hidden>
              </Col>
            </Row>
            <Row justify="center" style={{textAlign: 'center'}}>
              <Col style={{textAlign: 'center'}}>
                <div className="homeDescriptionContainer">
                  <p className={`homeDescription ${getLanguage()}`}>{t('home.expert')}<span>{t('home.productSpan')}</span>, <span>{t('home.designSpan')}</span>{t('home.and')}<span>{t('home.developmentSpan')}</span>.</p>
                </div>
              </Col>
            </Row>
            <Row justify="center">
              <Col style={{textAlign: 'center'}}>
              <Visible sm xs md>
                <img className="peeps" alt="Shockoe Mobile - Peeps" height="104" width="264" src={Peeps}/>
              </Visible>
              <Hidden sm xs md>
                <img className="peeps" alt="Shockoe Mobile - Peeps" height="318" width="792" src={PeepsDesktop}/>
                </Hidden>
              </Col>
            </Row>
          </div>
          <Visible sm xs md>
            <AnchorLink href="#Expertise" style={{textDecoration: 'none'}}>
              <div className={`homeFooter ${this.state.visibleHomeFooter ? 'visible':'hidden'}`}>
                <Row justify="center" style={{paddingTop: '48px'}}>
                  <Col style={{textAlign: 'center'}}>
                    <img alt="Shockoe Mobile - Scroll" height="24" width="22" src={ScrollHelp}/>
                  </Col>
                </Row>
              </div>
            </AnchorLink>
          </Visible>
          <Hidden sm xs md>
            <div className={`homeFooterDesktop`}></div>
          </Hidden>
        </div>
        <div id="Expertise" style={{ marginBottom:'44px' }}>
          <h3 className={`expertiseTitle ${getLanguage()}`}>{t('expertise.title')}</h3>
          <Visible sm xs md>
            <Carousel infinite={ false } dotsDisabled={false} paddingLeft={this.state.paddingLeftExp} paddingRight={this.state.paddingRightExp}>
              {
                [0,1,2].map((item, k) => {
                  return <OurExpertise 
                            key={k} 
                            item={item} 
                            textFront={OurExpertiseScreenValues[item].text}
                            textBack={OurExpertiseScreenValues[item].textBack}
                            tapText = {OurExpertiseScreenValues[item].tap}
                          />;
                })
              }
            </Carousel>
          </Visible>
          <Hidden sm xs md>
            <div className="cardsContainer marginBottom">
              {
                [0,1,2].map((item, k) => {
                  return <OurExpertise 
                          key={k} 
                          item={item} 
                          textFront={OurExpertiseScreenValues[item].text}
                          textBack={OurExpertiseScreenValues[item].textBack}
                          tapText = {OurExpertiseScreenValues[item].tap}
                        />;
                })
              }
            </div>
          </Hidden>
        </div>

        <Visible sm xs md>
          <Strategy texts={StrategyscreenValues} />
        </Visible>
        <Hidden sm xs md>
          <h1 className={`strategySectionTitle ${getLanguage()}`}>{t('strategy.title')}</h1>
          <div className="cardsContainerProducts">
            {
              [0,1,2].map((item, key) => {
                return <Products key={key} index={item} texts={StrategyscreenValues} />;
              })
            }
          </div>
        </Hidden>
        <Visible sm xs md lg>
          <ProductSuccess 
            title = { t('productSuccess.title') }
            texts = {ProductSuccessScreenValues} 
            tap = { window.innerWidth >= 1024 ? t('productSuccess.click') : t('productSuccess.tap') }          
          />
        </Visible>
        <Hidden sm xs md lg>
          <ProductSuccessDesktop 
            title = { t('productSuccess.title') }
            texts = {ProductSuccessScreenValues} 
            tap = { window.innerWidth >= 1024 ? t('productSuccess.click') : t('productSuccess.tap') }          
          />
        </Hidden>
        <ProblemsSolve texts = { ProblemsSolveValues }/>        
        <div id="products">
          <Hidden sm xs md>
            <h1 className={`strategySectionTitle ${getLanguage()}`}>{t('cards.title')}</h1>            
          </Hidden>
          <Container id="Asset">
            <h3 className={`productsTitle ${getLanguage()}`}>{ t('cards.products.title') }</h3>
            <Visible sm xs>
              <Carousel infinite={ false  } paddingLeft={this.state.paddingLeft} paddingRight={this.state.paddingRight}>
                {
                  ["Arrow", "Oneok"].map((item, key) => {
                    return <Card key={key} product={item}  products={cardProducts} solutionText={ t('cards.solutionText')} />;
                  })
                }
              </Carousel>
            </Visible>
            <Hidden sm xs>
              <Container className="customContainer">
                <div className="cardsContainerCaseStudies">
                  {
                    ["Arrow", "Oneok"].map((item, key) => {
                      return <Card key={key} product={item}  products={cardProducts} solutionText={ t('cards.solutionText')} />;
                    })
                  }
                </div>
              </Container>
            </Hidden>
          </Container>

          <Container id="Employee">
            <h3 className={`productsTitle ${getLanguage()}`}>{ t('cards.employee.title') }</h3>
            <Visible sm xs>
              <Carousel infinite={ false  } paddingLeft={this.state.paddingLeft} paddingRight={this.state.paddingRight}>
                {
                  ["JBHunt", "ACMoore"].map((item, key) => {
                    return <Card key={key} product={item}  products={cardProducts} solutionText={ t('cards.solutionText')} />;
                  })
                }
              </Carousel>
            </Visible>
            <Hidden sm xs>
              <Container className="customContainer">
                <div className="cardsContainerCaseStudies">
                  {
                    ["JBHunt", "ACMoore"].map((item, key) => {
                      return <Card key={key} product={item}  products={cardProducts} solutionText={ t('cards.solutionText')} />;
                    })
                  }
                </div>
              </Container>
            </Hidden>
          </Container>

          <Container id="Retaining">
            <h3 className={`productsTitle ${getLanguage()}`}>{ t('cards.retaining.title') }</h3>
            <Visible sm xs>
              <Carousel infinite={ false  } paddingLeft={this.state.paddingLeft} paddingRight={this.state.paddingRight}>
                {
                  ["Vacu", "Winn"].map((item, key) => {
                    return <Card key={key} product={item}  products={cardProducts} solutionText={ t('cards.solutionText')} />;
                  })
                }
              </Carousel>
            </Visible>
            <Hidden sm xs>
              <Container className="customContainer">
                <div className="cardsContainerCaseStudies">
                  {
                    ["Vacu", "Winn"].map((item, key) => {
                      return <Card key={key} product={item}  products={cardProducts} solutionText={ t('cards.solutionText')} />;
                    })
                  }
                </div>
              </Container>
            </Hidden>
          </Container>

          <Container id="Quality">
            <h3 className={`productsTitle ${getLanguage()}`}>{ t('cards.quality.title') }</h3>
            <Visible sm xs>
              <Carousel infinite={ false  } paddingLeft={this.state.paddingLeft} paddingRight={this.state.paddingRight}>
                {
                  ["American", "Valacta"].map((item, key) => {
                    return <Card key={key} product={item}  products={cardProducts} solutionText={ t('cards.solutionText')} />;
                  })
                }
              </Carousel>
            </Visible>
            <Hidden sm xs>
              <Container className="customContainer">
                <div className="cardsContainerCaseStudies">
                  {
                    ["American", "Valacta"].map((item, key) => {
                      return <Card key={key} product={item}  products={cardProducts} solutionText={ t('cards.solutionText')} />;
                    })
                  }
                </div>
              </Container>
            </Hidden>
          </Container>

          <Container id="Improving">
            <h3 className={`productsTitle ${getLanguage()}`}>{ t('cards.confident.title') }</h3>
            <Visible sm xs>
              <Carousel infinite={ false  } paddingLeft={this.state.paddingLeft} paddingRight={this.state.paddingRight}>
                {
                  ["Farmers", "Dominion"].map((item, key) => {
                    return <Card key={key} product={item}  products={cardProducts} solutionText={ t('cards.solutionText')} />;
                  })
                }
              </Carousel>
            </Visible>
            <Hidden sm xs>
              <Container className="customContainer">
                <div className="cardsContainerCaseStudies">
                  {
                    ["Farmers", "Dominion"].map((item, key) => {
                      return <Card key={key} product={item}  products={cardProducts} solutionText={ t('cards.solutionText')} />;
                    })
                  }
                </div>
              </Container>
            </Hidden>
          </Container>

          <Container id="Emerging" className="marginBottom">
            <h3 className={`productsTitle ${getLanguage()}`}>{ t('cards.emerging.title') }</h3>
            <Visible sm xs md>
              <Carousel dotsDisabled={false} infinite={ false  } paddingLeft={this.state.paddingLeft} paddingRight={this.state.paddingRight}>
                {
                  ["Floorco", "Playart", "Skafos"].map((item, key) => {
                    return <Card key={key} product={item}  products={cardProducts} solutionText={ t('cards.solutionText')} />;
                  })
                }
              </Carousel>
            </Visible>
            <Hidden sm xs md>
              <Container className="customLargeContainer">
                <div className="cardsContainerCaseStudies">
                  {
                    ["Floorco", "Playart", "Skafos"].map((item, key) => {
                      return <Card key={key} product={item}  products={cardProducts} solutionText={ t('cards.solutionText')} />;
                    })
                  }
                </div>
              </Container>
            </Hidden>
          </Container>
        </div>

        <div id="whitePapers">
          <Visible sm xs md>
            <Container className="marginBottom">
              <h3 className={`productsTitle ${getLanguage()}`}>{ t('cards.industry.title') }</h3>
              <Carousel dotsDisabled={false} infinite={false} paddingLeft={this.state.paddingLeft} paddingRight={this.state.paddingRight}>
                {
                  [1,2,3,4,5,6].map((item, key) => {
                    return <CardWP key={key} wpId={item} whitePapers={whitePapers} emailDesc={t('cards.industry.emailDesc')} />;
                  })
                }
              </Carousel>
            </Container>
          </Visible>
          <Hidden sm xs md>
            <h3 className={`productsTitle ${getLanguage()}`}>{ t('cards.industry.title') }</h3>
            <Carousel dotsDisabled={false} infinite={false} paddingLeft={this.state.paddingLeft} paddingRight={this.state.paddingRight}>
              {
                [1,2,3,4,5,6].map((item, key) => {
                  return <CardWP key={key} wpId={item} whitePapers={whitePapers} emailDesc={t('cards.industry.emailDesc')} />;
                })
              }
            </Carousel>
          </Hidden>
          
        </div>
        <ContactUs title={ t('contactUs.title') } text={ t('contactUs.text') }/>
        <Footer menuText={menuText} showLogo={this.state.visibleHomeFooter} />
        <Conditional condition={this.props.installButton}>
            <AddHome promptText={promptText} {...this.props}/>
        </Conditional>
      </div>
    );
  }
};

export default translate(Home);