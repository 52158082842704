import React, {Component} from 'react';
import { Container ,Row, Col, Visible, Hidden } from 'react-grid-system';
import "./ContactUs.css";
import { getLanguage } from 'react-switch-lang';
import edwinImage from "../../assets/images/Edwin.png";
import alexImage from "../../assets/images/Alex.png";
import monsterImage from "../../assets/images/mobileMonster.png";
import shockoeGrayImage from "../../assets/images/shockoeGray.png";
import twitterImage from "../../assets/images/twitter.png";
import facebookimage from "../../assets/images/facebook.png";
import linkedinImage from "../../assets/images/linkedin.png";
import instagramImage from "../../assets/images/instagram.png";
import HubspotForm from 'react-hubspot-form';

class ContactUs extends Component {
  render() {
    return (
      <div id='Contact' className="contactUsContainer">
        <Container>
          <Row justify="center">
            <Col xs={12} sm={12} md={5}>
              <Row>
                <Col sm={12}>
                  <h2 className={`h2ContactUs ${getLanguage()}`}>{ this.props.title }</h2>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <p className={`pContactUs ${getLanguage()}`}>{ this.props.text }</p>
                </Col>
              </Row>
              <Row justify="center" className="customContactRow">
                <Col xs={6} style={{textAlign: 'center'}}>
                  <Visible xs sm md>
                    <img className="photo" src={ edwinImage } alt="Shockoe Mobile - CEO" />
                  </Visible>
                  <Hidden xs sm md>
                    <img className="photoDesktop" src={ edwinImage } alt="Shockoe Mobile - CEO" />
                  </Hidden>
                  <div className="textContainer">
                      <p><span>Edwin Huertas</span></p>
                      <p>CEO</p>
                  </div>
                </Col>
                <Col xs={6} style={{textAlign: 'center'}}>
                  <Visible xs sm md>
                    <img className="photo" src={ alexImage } alt="Shockoe Mobile - COO" />
                  </Visible>
                  <Hidden xs sm md>
                    <img className="photoDesktop" src={ alexImage } alt="Shockoe Mobile - COO" />                
                  </Hidden>
                  <div className="textContainer">
                      <p><span>Alejandro Otañez</span></p>
                      <p>COO</p>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={12} md={4} className="customContactCol">
              <Row>                        
                <Col>
                  <div id="formContactUs" style={{ marginTop:'48px' }} >
                      <HubspotForm
                      portalId='2208386'
                      formId='8f2140ee-2856-4154-ae8e-91169b655e8a'                                            
                      loading={<div>Loading...</div>}     
                      css=""         
                      translations= {
                        {
                          ar: {
                            required: "Hey! That's required!",
                            invalidDate: "Please enter a real date",
                            submitText: "Custom Submit Button Text",
                            fieldLabels: {
                              email: "Electronic mail"
                            }
                          }
                        }
                      }
                      />
                  </div>                            
                </Col>                        
              </Row>
            </Col>
          </Row>
        </Container>
        <div className="contactFooter">
          <div className="copyRContainer">
            <img className="shockoeMonster" src={ monsterImage } alt="Shockoe Mobile - Monster" />
            <a href="https://shockoe.com/">
                <p className="pFooterContactUs" >2019 © SHOCKOE</p>
            </a>
          </div>
          <div className="logoContainer">
            <a href="https://shockoe.com/">
                <img className="imageFotterContactUs" src={ shockoeGrayImage } alt="Shockoe Mobile - Logo" />
            </a>
          </div>  
          <div className="logoContainer">
            <a href="https://twitter.com/Shockoe">
                <img className="imageFotterContactUs" src={ twitterImage } alt="Shockoe Mobile - Twitter" />
            </a>
          </div>
          <div className="logoContainer">
            <a href="https://facebook.com/Shockoe">
                <img className="imageFotterContactUs" src={ facebookimage } alt="Shockoe Mobile - Facebook" />
            </a>
          </div>
          <div className="logoContainer">
            <a href="https://www.linkedin.com/company/shockoe/">
                <img className="imageFotterContactUs" src={ linkedinImage } alt="Shockoe Mobile - Linkedin" />
            </a>
          </div>
          <div className="logoContainer">
            <a href="https://www.instagram.com/shockoe_rva/">
                <img className="imageFotterContactUs" src={ instagramImage } alt="Shockoe Mobile - Instagram" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactUs;