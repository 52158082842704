import React from 'react';
import Chart from "react-apexcharts";

function getChartOpts(bckColor, barColor, value, textChart) {
	return {
		options: {
			chart: {                
				animations: {
					dynamicAnimation: {
							enabled: true,
							speed: 2000
					}
				}		
			},
			plotOptions: {
				radialBar: {
					hollow: {
						size: '90%',
						background: bckColor,
						position: 'front'						
					},
					track: {
						background: '#fff',
						strokeWidth: '17%',
						margin: 0, // margin is in pixels
					},
					dataLabels: {
						name: {
                            show: false,                            
                          },						
						value: {							
							show: false,
						}
                    }
				}
			},
            colors:[barColor],            
		},
        series: [value],        
	};
}


export default function PercentageChart(props) {
    const {backgroundColor, barColor, value } = props;    
	const opts = getChartOpts(backgroundColor, barColor, value);
	return <Chart options={opts.options} series={opts.series} type="radialBar" height={350} />
}

PercentageChart.defaultProps = {
	backgroundColor: '#F1F8EE',
	barColor: '#457B2D',
	value: 0,
	size: "150",
    fontSize: '25px',    
};